import {useEffect, useState} from 'react';
import { useStore } from 'effector-react';
import { Title } from 'src/shared/ui';
import { Form, Input, Button, Card, Spin, Select, Menu } from 'antd';
import { trimNullsFromString } from 'src/shared/lib';

import { CommissionTypes, CommissionSettingsKeys } from './types';
import { $commission, initCommissionPage, submitUpdateCommissionData, getCommissionFx } from './model';
import { ffdSelectOptions } from './config';
import styles from './Commission.module.less';

export const Commission = () => {
  const [form] = Form.useForm();
  const [selectedTab, setSelectedTab] = useState('/client');
  const commission = useStore($commission);
  const isLoading = useStore(getCommissionFx.pending);

  const formSubmitHandler = (values: CommissionTypes): void => {
    submitUpdateCommissionData({
      settings: [
        {
          key: CommissionSettingsKeys.CommissionClientAppCard,
          value: trimNullsFromString(values.commission_client_app_card),
        },
        {
          key: CommissionSettingsKeys.CommissionClientAppCardIntermediarySkipped,
          value: trimNullsFromString(values.commission_client_app_card_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionClientAppCash,
          value: trimNullsFromString(values.commission_client_app_cash),
        },
        {
          key: CommissionSettingsKeys.CommissionClientAppCashIntermediarySkipped,
          value: trimNullsFromString(values.commission_client_app_cash_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionClientLkCard,
          value: trimNullsFromString(values.commission_client_lk_card),
        },
        {
          key: CommissionSettingsKeys.CommissionClientLkCardIntermediarySkipped,
          value: trimNullsFromString(values.commission_client_lk_card_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionClientLkCash,
          value: trimNullsFromString(values.commission_client_lk_cash),
        },
        {
          key: CommissionSettingsKeys.CommissionClientLkCashIntermediarySkipped,
          value: trimNullsFromString(values.commission_client_lk_cash_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.BankCommission,
          value: trimNullsFromString(values.bank_commission_percent),
        },
        {
          key: CommissionSettingsKeys.BankMinAmountCommission,
          value: trimNullsFromString((Number(values.bank_commission_min_amount) * 100).toString()),
        },
        {
          key: CommissionSettingsKeys.Ffd_version,
          value: values.ffd_version,
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryAppCard,
          value: trimNullsFromString(values.commission_intermediary_app_card),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryAppCardIntermediarySkipped,
          value: trimNullsFromString(values.commission_intermediary_app_card_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryAppCash,
          value: trimNullsFromString(values.commission_intermediary_app_cash),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryAppCashIntermediarySkipped,
          value: trimNullsFromString(values.commission_intermediary_app_cash_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryLkCard,
          value: trimNullsFromString(values.commission_intermediary_lk_card),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryLkCardIntermediarySkipped,
          value: trimNullsFromString(values.commission_intermediary_lk_card_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryLkCash,
          value: trimNullsFromString(values.commission_intermediary_lk_cash),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediaryLkCashIntermediarySkipped,
          value: trimNullsFromString(values.commission_intermediary_lk_cash_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediarySellerCard,
          value: trimNullsFromString(values.commission_intermediary_seller_card),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediarySellerCardIntermediarySkipped,
          value: trimNullsFromString(values.commission_intermediary_seller_card_intermediary_skipped),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediarySellerCash,
          value: trimNullsFromString(values.commission_intermediary_seller_cash),
        },
        {
          key: CommissionSettingsKeys.CommissionIntermediarySellerCashIntermediarySkipped,
          value: trimNullsFromString(values.commission_intermediary_seller_cash_intermediary_skipped),
        },
      ],
    });
  };

  useEffect(() => {
    initCommissionPage();
  }, []);

  useEffect(() => {
    form.setFieldsValue(commission);
  }, [form, commission]);

  return (
    <div className={styles.commission}>
      <Card>
        <Title>Установка комиссии</Title>

        {isLoading ? (
          <div className={styles.center}>
            <Spin size="large" />
          </div>
        ) : (
            <div>
              <Menu mode="horizontal"
                    className={styles.menu}
                    selectedKeys={[selectedTab]}
              >
                <Menu.Item key="/client" onClick={() => setSelectedTab('/client')}>
                  <span>Клиент</span>
                </Menu.Item>
                <Menu.Item key="/posrednik" onClick={() => setSelectedTab('/posrednik')}>
                  <span>Посредник</span>
                </Menu.Item>
                <Menu.Item key="/bank" onClick={() => setSelectedTab('/bank')}>
                  <span>Банк</span>
                </Menu.Item>
              </Menu>
                <Form
                    form={form}
                    className={styles.form}
                    name="basic"
                    id="commissionForm"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={formSubmitHandler}
                    autoComplete="off">
                  <div style={{display: selectedTab === '/client' ? 'block' : 'none'}}>
                    <span className={styles.subtitle}>Заказы в приложении allall</span>
                    <Form.Item
                        label="Комиссия при оплате картой, %"
                        name="commission_client_app_card"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате картой',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Комиссия при оплате картой с пропуском посредника, %"
                        name="commission_client_app_card_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате картой с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Комиссия при оплате в кассу, %"
                        name="commission_client_app_cash"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате в кассу',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Комиссия при оплате в кассу с пропуском посредника, %"
                        name="commission_client_app_cash_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате в кассу с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <span className={styles.subtitle}>Заказы с сайта lk.allall.ru</span>
                    <Form.Item
                        label="Комиссия при оплате картой, %"
                        name="commission_client_lk_card"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате картой',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Комиссия при оплате картой с пропуском посредника, %"
                        name="commission_client_lk_card_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате картой с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Комиссия при оплате в кассу, %"
                        name="commission_client_lk_cash"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате в кассу',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Комиссия при оплате в кассу с пропуском посредника, %"
                        name="commission_client_lk_cash_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию при оплате в кассу с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                  </div>

                  <div style={{display: selectedTab === '/posrednik' ? 'block' : 'none'}}>
                    <span className={styles.subtitle}>Задания из приложения allall</span>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное картой, %"
                        name="commission_intermediary_app_card"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное картой с пропуском посредника, %"
                        name="commission_intermediary_app_card_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное в кассу, %"
                        name="commission_intermediary_app_cash"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное в кассу с пропуском посредника, %"
                        name="commission_intermediary_app_cash_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <span className={styles.subtitle}>Задания с сайта lk.allall.ru</span>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное картой, %"
                        name="commission_intermediary_lk_card"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное картой с пропуском посредника, %"
                        name="commission_intermediary_lk_card_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное в кассу, %"
                        name="commission_intermediary_lk_cash"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное в кассу с пропуском посредника, %"
                        name="commission_intermediary_lk_cash_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <span className={styles.subtitle}>Задания, проходящие через поставщиков seller.allall.ru</span>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное картой, %"
                        name="commission_intermediary_seller_card"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное картой с пропуском посредника, %"
                        name="commission_intermediary_seller_card_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное в кассу, %"
                        name="commission_intermediary_seller_cash"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Вознаграждение за задание, оплаченное в кассу с пропуском посредника, %"
                        name="commission_intermediary_seller_cash_intermediary_skipped"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу с пропуском посредника',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                  </div>
                  <div style={{display: selectedTab === '/bank' ? 'block' : 'none'}}>
                    <Form.Item
                        label="Минимальная сумма комиссии банка, ₽"
                        name="bank_commission_min_amount"
                        style={{marginTop: 24}}
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите минимальную сумму комиссии банка',
                          },
                        ]}>
                      <Input type="number" min={0} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Комиссия банка, %"
                        name="bank_commission_percent"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите комиссию банка',
                          },
                        ]}>
                      <Input type="number" min={0} max={100} step={0.01} />
                    </Form.Item>
                    <Form.Item label="Версия ФФД" name="ffd_version">
                      <Select options={ffdSelectOptions} placeholder="Выберите версию ФФД" />
                    </Form.Item>
                  </div>
                    <Button disabled={!commission} htmlType="submit" type="primary" className={styles.button}>
                        Сохранить изменения
                    </Button>
                </Form>
            </div>

        )}
      </Card>
    </div>
  );
};
